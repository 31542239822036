
/* color: #F2BC44;
  color: #185B9F; */


.containerBox {
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 800px;
}

h1 {
	color: #fff;
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 20px;
}

.form-group {
	margin-bottom: 20px;
}

.label {
	display: block;
	font-weight: bold;
	margin-bottom: 5px;
}

.input {
	width: 30%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-bottom: 10px;
}

.button {
	padding: 10px 20px;
	background-color: #fff;
	color: #185B9F;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.button:hover:not([disabled]) {
	background-color: #F2BC44;
	color: #fff;
}

.button[disabled] {
  cursor: not-allowed;
  opacity: 0.6;
}

.errMsg {
	color: red;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
}
