.custom-rich-text p {
  margin-bottom: 1.5em;
}

.custom-rich-text img {
  margin: 0 auto;
}

.custom-rich-text a {
  color: #F2BC44;
}

.custom-rich-text strong {
  color: #185B9F;
}

.custom-rich-text iframe {
  width: 100%; 
  height: auto;
  margin: 0 auto;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  /* For screens wider than 768px */
  .custom-rich-text iframe {
    width: 500px;
    height: 303px;
  }
}

@media (max-width: 767px) {
  /* For screens narrower than 768px */
  .custom-rich-text iframe {
    width: 100%;
    height: calc(100vw * 0.6);
    max-height: 300px;
  }
}